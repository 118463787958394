import $ from 'jquery'
import modal from './modal'
import {playAudio} from './sound'
import { throttle } from './throttle'
import { arrayShuffle } from './arrayShuffle'

export default class Charts {
  currentQuestionIndex = 0
  score = 0
  name = ''

  constructor() { }
  /*
  {
    questions: [
      [
        {
          title: string,
          answers: [
            {
              text: string,
              score: number
            }
          ]
        }
      ]
    ],
    results: [
      {
        scores: number[],
        image_url: string,
        name: string
      }
    ]
  }
  */

  initialize(data, renderShareUrl) {
    this.$el = $('.js-charts')
    if(this.$el.length <= 0) return
    this.data = data
    this.$questionView = $('.js-charts-question-view')
    this.$startView = $('.js-charts-start-view')
    this.$resultView = $('.js-charts-result-view')
    this.$nameInput = this.$el.find('.js-charts-name-input')
    this.$nameErrorMessage = this.$el.find('.js-charts-name-error-message')
    this.$nameBtn = this.$el.find('.js-charts-name-btn')
    this.$nameBtn.on('click', () => {
      this.onClickNameBtn()
    })
    this.$question = this.$el.find('.js-charts-question')
    this.$answers = this.$el.find('.js-charts-answers')
    this.$questionNo = $('.js-charts-question-no')
    this.$result = this.$el.find('.js-charts-result')
    this.$restartBtn = this.$el.find('.js-charts-restart')
    this.$restartBtn.on('click', () => {
      this.onClickRestart()
    })
    this.$xShareBtn = this.$el.find('.js-charts-x-share')
    this.renderShareUrl = renderShareUrl
    this.start()
  }

  onClickRestart() {
    this.start()
  }

  onClickNameBtn() {
    this.name = this.$nameInput
      .val()
      .replace('<','＜')
      .replace('>','＞')
      .replace('?','？')
      .replace('&','＆')
    if(!this.name) {
      this.$nameErrorMessage.text('名前を入力してください')
    } else {
      this.$nameErrorMessage.text('')
      this.initQuestion()
    }
  }

  initQuestion() {
    this.$resultView.hide()
    this.$questionView.show()
    this.$startView.hide()
    const q = this.data.questions[this.currentQuestionIndex]
    const randomIndex = Math.floor(Math.random() * q.length);
    this.renderQuestion(q[randomIndex])
  }

  renderQuestion(q) {
    this.$questionNo.text('Q' + (this.currentQuestionIndex + 1))
    this.$question.text(q.title)
    const answers = arrayShuffle(q.answers)
    this.$answers.html(`
      ${answers.map((a) => {
        return `
          <button class="js-answer-btn chartsContents-Btn" score="${a.score}">
            ${a.text}
          </button>
        `
      }).join('')}
    `)
    const $answers = this.$el.find('.js-answer-btn')
    $answers.on('click', ($e) => {
      this.onClickAnswer($e)
    })
  }

  showResult() {
    const result = this.chooseResult(this.score)
    const $results = this.$result.find('[result]')
    $results.hide()
    if(!result) {
      return
    }
    const $result = this.$result.find(`[result="${result.key}"]`)
    $result.show()
    const shareUrl = this.shareUrl(result)
    this.$xShareBtn.attr('href', shareUrl)

    this.$resultView.show()
    this.$questionView.hide()
    this.$startView.hide()
  }

  chooseResult(score) {
    return this.data.results.find((a) => {
      return a.scores.includes(score)
    })
  }

  onClickAnswer($e) {
    const score = $($e.target).attr('score')
    ++this.currentQuestionIndex
    this.score += parseInt(score)
    if(this.data.questions.length <= this.currentQuestionIndex) {
      this.showResult()
    } else {
      this.initQuestion()
    }
  }

  start() {
    this.$resultView.hide()
    this.$questionView.hide()
    this.$startView.show()
    this.currentQuestionIndex = 0
    this.score = 0
  }

  shareUrl(result){
    const url = this.$el.attr('share-url') + '?result=' + result.key
    return this.renderShareUrl(result, this.name, url)
  }
}
