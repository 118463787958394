import $ from 'jquery'
import Charts from './charts'

const renderShareUrl = (result, name, url) => {
  const shareText = `${result.description.replace('あなた', name + 'さん')}`
  return `https://twitter.com/intent/tweet?text=${shareText}&url=${url}&hashtags=ビバレン,ビバレン運命のMV`
}

export default class DestinyMV {
  constructor() { }

  initialize() {
    this.$el = $('.js-destiny-mv')
    if(this.$el.length <= 0) return
    console.log('destiny mv!')
    const charts = new Charts()
    charts.initialize(data, renderShareUrl)
  }

  renderShareUrl
}

const data = {
  questions: [
    [
      {
        title: '一番気になる言葉は？',
        answers: [
          {
            text: '恋愛',
            score: 1
          },
          {
            text: '家族',
            score: 2
          },
          {
            text: '友情',
            score: 4
          }
        ]
      },
      {
        title: '好きなおにぎりの具は？',
        answers: [
          {
            text: '昆布',
            score: 1
          },
          {
            text: '明太子',
            score: 2
          },
          {
            text: 'ツナマヨ',
            score: 4
          }
        ]
      },
      {
        title: 'お休みの日は何したい？',
        answers: [
          {
            text: 'なにもしたくない',
            score: 1
          },
          {
            text: 'のんびりしたい',
            score: 2
          },
          {
            text: '遊びたい',
            score: 4
          }
        ]
      },
    ], [
      {
        title: '人によく言われるのは？',
        answers: [
          {
            text: '優しいね',
            score: 8
          },
          {
            text: '真面目だね',
            score: 16
          },
          {
            text: '元気だね',
            score: 32
          }
        ]
      },
      {
        title: '許せないのは？',
        answers: [
          {
            text: '嘘',
            score: 8
          },
          {
            text: '遅刻',
            score: 16
          },
          {
            text: '喧嘩',
            score: 32
          }
        ]
      },
      {
        title: '魔法が1つ使えたら？',
        answers: [
          {
            text: '透明人間になる',
            score: 8
          },
          {
            text: '空を飛ぶ',
            score: 16
          },
          {
            text: 'お金持ちになる',
            score: 32
          }
        ]
      },
    ], [
      {
        title: '気になる数字は？',
        answers: [
          {
            text: '１',
            score: 64
          },
          {
            text: '１４',
            score: 128
          },
          {
            text: '７７７',
            score: 256
          }
        ]
      },
      {
        title: '今の気分を天気で表すと？',
        answers: [
          {
            text: '雨',
            score: 64
          },
          {
            text: '曇り',
            score: 128
          },
          {
            text: '晴れ',
            score: 256
          }
        ]
      },
      {
        title: '言葉は？',
        answers: [
          {
            text: '時に人を傷つける',
            score: 64
          },
          {
            text: 'ただの音声',
            score: 128
          },
          {
            text: '口にしないと伝わらない',
            score: 256
          }
        ]
      },
    ]
  ],
  results: [
    {
      scores: [73],
      image_url: '',
      name: 'TAIYO',
      description: '今のあなたに一番マッチするMVは…「愛の歌」です！https://youtu.be/s6RnLV15hq0 あなたもやってみよう！',
      key: 'taiyo'
    }, {
      scores: [76,84],
      image_url: '',
      name: 'SUBARU',
      description: '今のあなたに一番マッチするMVは…「From Me」です！https://youtu.be/NTdktgsJaAI あなたもやってみよう！',
      key: 'subaru'
    }, {
      scores: [161,266],
      image_url: '',
      name: 'ISSEI',
      description: '今のあなたに一番マッチするMVは…「蜜柑」です！https://youtu.be/207UaC7bwto あなたもやってみよう！',
      key: 'issei'
    }, {
      scores: [82],
      image_url: '',
      name: 'MIO',
      description: '今のあなたに一番マッチするMVは…「Go My Own Way」です！https://youtu.be/97ni-WfjBOo あなたもやってみよう！',
      key: 'mio'
    }, {
      scores: [164,265],
      image_url: '',
      name: 'JINTARO',
      description: '今のあなたに一番マッチするMVは…「Groovy Night」です！https://youtu.be/Ff-7RBpbPd0 あなたもやってみよう！',
      key: 'jintaro'
    }, {
      scores: [81],
      image_url: '',
      name: 'CION',
      description: '今のあなたに一番マッチするMVは…「Still On Journey」です！https://youtu.be/J4xRt158FZY あなたもやってみよう！',
      key: 'cion'
    }, {
      scores: [140,289],
      image_url: '',
      name: 'NAGOMU',
      description: '今のあなたに一番マッチするMVは…「Said that」です！https://youtu.be/0lJ2WAm5A2Q あなたもやってみよう！',
      key: 'nagomu'
    }, {
      scores: [145],
      image_url: '',
      name: 'CUC',
      description: '今のあなたに一番マッチするMVは…「Fight-or-Flight」です！https://youtu.be/RtCT_36QkkA あなたもやってみよう！',
      key: 'cuc'
    }, {
      scores: [148],
      image_url: '',
      name: '39YEAH↗',
      description: '今のあなたに一番マッチするMVは…「This is my way」です！https://youtu.be/lhyVP8mOrcc あなたもやってみよう！',
      key: 'sakuya'
    }, {
      scores: [98,290],
      image_url: '',
      name: 'REY',
      description: '今のあなたに一番マッチするMVは…「PreOrder」です！https://youtu.be/FwORztQH_q8 あなたもやってみよう！',
      key: 'rey'
    }, {
      scores: [137,292],
      image_url: '',
      name: 'AUGURI',
      description: '今のあなたに一番マッチするMVは…「what about love」です！https://youtu.be/mR7D5BCWnGo あなたもやってみよう！',
      key: 'auguri'
    }, {
      scores: [100,276],
      image_url: '',
      name: 'FUTABA',
      description: '今のあなたに一番マッチするMVは…「ビバレンソング！」です！https://youtu.be/XcBfBxTsbGs あなたもやってみよう！',
      key: 'futaba'
    }, {
      scores: [162],
      image_url: '',
      name: 'LION',
      description: '今のあなたに一番マッチするMVは…「サンライズ・イン・ジ・アイズ」です！https://youtu.be/Z4gkOiC-Sik あなたもやってみよう！',
      key: 'lion'
    }, {
      scores: [97],
      image_url: '',
      name: 'KAZU',
      description: '今のあなたに一番マッチするMVは…「スペシャル！」です！https://youtu.be/BarlUWZtkeI あなたもやってみよう！',
      key: 'kazu'
    }, {
      scores: [138],
      image_url: '',
      name: 'XLAMV1',
      description: '今のあなたに一番マッチするMVは…「アワソラ」です！https://youtu.be/uktjgH6YCNc あなたもやってみよう！',
      key: 'xlamv1'
    }, {
      scores: [146],
      image_url: '',
      name: 'XLAMV2',
      description: '今のあなたに一番マッチするMVは…「About Us」です！https://youtu.be/-yFhGCdWrRU あなたもやってみよう！',
      key: 'xlamv2'
    }, {
      scores: [274,74],
      image_url: '',
      name: 'XLAMV3',
      description: '今のあなたに一番マッチするMVは…「A.S.A.P」です！https://youtu.be/YT26snwpP1o あなたもやってみよう！',
      key: 'xlamv3'
    }, {
      scores: [273],
      image_url: '',
      name: 'FUN4RE',
      description: '今のあなたに一番マッチするMVは…「Feelin!!!!」です！https://youtu.be/tsQHBzzm4Qs あなたもやってみよう！',
      key: 'fun4re'
    }, {
      scores: [268],
      image_url: '',
      name: 'ILLUVISTA',
      description: '今のあなたに一番マッチするMVは…「Your Side...」です！https://youtu.be/cGaRmhl4l7Q あなたもやってみよう！',
      key: 'illuvista'
    },
  ]
}

